/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import api from 'api';
import { loadingColor, white } from 'styles/colorProvider';
import {
  Container,
  LoadingArea,
  ButtonArea,
  Printable,
  PrintStyles,
  Header,
  HrHeader,
  Footer,
  FooterRow,
  LogoIcon,
  Row,
  SectionContainer,
  SectionTitle,
  RowLabel,
  RowText,
  Column,
  SectionSmall,
  FooterText,
  ContainerBetween,
  ColumnBetween,
  RowBetween,
} from './styles';
import { formatCPF, formatCNPJ, formatPlate, formatPhone, formatZipCode, formatCurrency } from 'react-data-formatter';
import { Button } from 'components';
import { BsPrinter } from 'react-icons/bs';
import { cpf } from 'cpf-cnpj-validator';
import { formatPolicyStatus } from 'utils/general';

function BackofficePrintPolicy({ location, history }) {
  const policyId = parseInt(location.pathname.split('detalhes-da-apolice/')[1]);

  const [policyData, setPolicyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOceanica, setIsOceanica] = useState(false);
  const [valueCoverage, setValueCoverage] = useState(0);
  const [valueService, setValueService] = useState(0);
  const [hasServices, setHasServices] = useState(false);
  const [hasCoverages, setHasCoverages] = useState(false);
  const loadData = async () => {
    const policyIssue = {
      method: 'GET',
      url: `/get-issue-document/${policyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitrisk-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(policyIssue);
    setIsOceanica(response.data.findAgent.id_partner === 5 ? true : false);
    let valueService = 0;
    let valueCoverage = 0;
    let hasCoverages = false;
    let hasServices = false;
    for (const item of response.data?.policyData?.coverages || []) {
      if (item.isService) {
        hasServices = true;
        valueService += Number(item.value);
      } else {
        hasCoverages = true;
        valueCoverage += Number(item.value);
      }
    }

    if (response?.data?.policyData?.oldCoverages?.length) {
      hasCoverages = true;
      setValueCoverage(response?.data?.price?.total_premium);
      if (response?.data?.proposalData) response.data.proposalData.iof = response?.data?.price?.iof;
    } else {
      setValueCoverage(valueCoverage);
    }

    setPolicyData(response.data);
    setHasCoverages(hasCoverages);
    setHasServices(hasServices);
    setLoading(false);
    setValueService(valueService);
  };

  useEffect(() => loadData(), []);

  // Manter essa alternância entre adjustment e adjustment_factor
  // PDF's antigos podem contar o outro parâmetro
  const adjustmentFactor = policyData?.vehicle?.auction
    ? '70%'
    : (policyData?.policyData?.adjustment_factor || policyData?.policyData?.adjustment) + '%';

  const isCpf = cpf.isValid(policyData?.client?.cpf);

  return (
    <>
      <PrintStyles />
      {!loading && (
        <ButtonArea>
          <Button style={{ color: white }} height="30px" width="130px" onClick={() => window.print()}>
            <BsPrinter size="15" style={{ marginRight: 10 }} />
            Imprimir
          </Button>
        </ButtonArea>
      )}
      {loading && (
        <LoadingArea>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
        </LoadingArea>
      )}
      {!loading && (
        <Container>
          <Printable>
            <Header>
              <div className="titleArea">
                <h2>
                  Olá, <b>{policyData?.client?.name?.toUpperCase()}</b>
                </h2>
                <h2>
                  Para você que está sempre em movimento,
                  <br />
                  aqui estão todos os detalhes do seu Seguro Auto.
                </h2>
              </div>
              <div className="logoArea">
                <img src={policyData?.logo} alt="logo" />
              </div>
            </Header>

            <HrHeader />

            <section>
              <b>Nº da proposta</b>
              <p>{policyData?.header?.numberProposal}</p>
              <b>Nº da apólice</b>
              <p>{policyData?.header?.numberPolicy}</p>

              <br />

              <ContainerBetween>
                <ColumnBetween width="50%">
                  <Row>
                    <RowLabel>Status da apólice:</RowLabel>
                    <RowText>{formatPolicyStatus(policyData?.status)}</RowText>
                  </Row>

                  <Row>
                    <RowLabel>Nº do bilhete:</RowLabel>
                    <RowText>{policyData?.header?.numberPolicy}</RowText>
                  </Row>
                </ColumnBetween>
                <ColumnBetween width="50%">
                  <Row>
                    <RowLabel>Início da Vigência:</RowLabel>
                    <RowText>{moment(policyData?.header?.effective_date).format('DD/MM/YYYY [às]  HH:mm ')}</RowText>
                  </Row>

                  <Row>
                    <RowLabel>Fim da Vigência:</RowLabel>
                    <RowText>{moment(policyData?.header?.expiry_date).format('DD/MM/YYYY [às]  HH:mm')}</RowText>
                  </Row>
                </ColumnBetween>
              </ContainerBetween>

              <br />

              <SectionSmall>
                Valores descritos nesta apolice referem-se a mensalidade. Seguro na modalidade mensal recorrente.
              </SectionSmall>
            </section>

            {policyData?.stipulating && (
              <section>
                <SectionContainer>
                  <SectionTitle>DADOS DO ESTIPULANTE</SectionTitle>
                </SectionContainer>

                <ContainerBetween>
                  <ColumnBetween width="50%">
                    <b>Nome do estipulante:</b>
                    <p>{policyData?.stipulating?.name?.toUpperCase()}</p>
                  </ColumnBetween>
                  <ColumnBetween width="50%">
                    <b>CNPJ:</b>
                    <p>{formatCNPJ(policyData?.stipulating?.document)}</p>
                  </ColumnBetween>
                </ContainerBetween>

                <ContainerBetween>
                  <ColumnBetween width="50%">
                    <b>Endereço:</b>
                    <p>{policyData?.stipulating?.address}</p>
                  </ColumnBetween>

                  <ColumnBetween width="50%">
                    <b>CEP:</b>
                    <p>{policyData?.stipulating?.zipcode}</p>
                  </ColumnBetween>
                </ContainerBetween>
              </section>
            )}

            <section>
              <SectionContainer>
                <SectionTitle>DADOS DO SEGURADO</SectionTitle>
              </SectionContainer>

              <Column>
                <b>Nome do segurado:</b>
                <p>{policyData?.client?.name?.toUpperCase()}</p>
              </Column>

              <ContainerBetween>
                <ColumnBetween width="30%">
                  <b>CPF:</b>
                  <p>{formatCPF(policyData?.client?.cpf)}</p>
                </ColumnBetween>
                <ColumnBetween width="45%">
                  <b>Data de nascimento:</b>
                  <p>{moment(policyData?.client?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</p>
                </ColumnBetween>

                <ColumnBetween width="25%">
                  <b>Sexo:</b>
                  <p>{policyData.client?.gender === 1 ? 'Masculino' : 'Feminino'}</p>
                </ColumnBetween>
              </ContainerBetween>

              <ContainerBetween>
                <ColumnBetween width="50%">
                  <b>E-mail:</b>
                  <p>{policyData?.client?.email}</p>
                </ColumnBetween>

                <ColumnBetween width="50%">
                  <b>Telefone:</b>
                  <p>{formatPhone(policyData?.client?.phone)}</p>
                </ColumnBetween>
              </ContainerBetween>

              <ContainerBetween>
                <Row>
                  <RowLabel>Logradouro:</RowLabel>
                  <RowText>{policyData?.address?.street}</RowText>
                </Row>
              </ContainerBetween>

              <ContainerBetween>
                <RowBetween gap="6px" width="25%">
                  <b>Número:</b>
                  <p>{policyData?.address?.number}</p>
                </RowBetween>
                <RowBetween gap="6px" width="25%">
                  <b>Bairro:</b>
                  <p>{policyData?.address?.district}</p>
                </RowBetween>
                <RowBetween gap="6px" width="25%">
                  <b>Cidade/UF:</b>
                  <p>
                    {policyData?.address?.city} / {policyData?.address?.state}
                  </p>
                </RowBetween>
                <RowBetween gap="6px" width="25%">
                  <b>CEP:</b>
                  <p>{policyData?.address?.zip_code}</p>
                </RowBetween>
              </ContainerBetween>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>CONDUTOR PRINCIPAL</SectionTitle>
              </SectionContainer>

              <Column>
                <b>Nome do condutor:</b>
                <p>{policyData?.proposalData?.maindriver_name || policyData?.proposalData?.user?.name}</p>
              </Column>

              <ContainerBetween>
                <ColumnBetween width="50%">
                  <b>CPF:</b>
                  <p>{formatCPF(policyData?.proposalData?.maindriver_document || policyData?.proposalData?.user?.cpf)}</p>
                </ColumnBetween>

                {policyData?.proposalData?.maindriver_birthdate && (
                  <ColumnBetween width="50%">
                    <b>Data de nascimento:</b>
                    <p>
                      {policyData?.proposalData?.maindriver_birthdate
                        ? moment(policyData?.proposalData?.maindriver_birthdate).utcOffset(0).format('DD/MM/YYYY')
                        : ''}
                    </p>
                  </ColumnBetween>
                )}
              </ContainerBetween>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>DADOS DO VEÍCULO</SectionTitle>
              </SectionContainer>

              <Row>
                <RowLabel>Nome do condutor:</RowLabel>
                <RowText>{policyData?.proposalData?.maindriver_name || policyData?.proposalData?.user?.name}</RowText>
              </Row>
              <Row>
                <RowLabel>Marca:</RowLabel>
                <RowText>{policyData?.vehicle?.TB_CONSTANT_VEHICLE_BRANDS?.name}</RowText>
              </Row>
              <Row>
                <RowLabel>Modelo:</RowLabel>
                <RowText>{policyData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.name}</RowText>
              </Row>
              <Row>
                <RowLabel>Cor:</RowLabel>
                <RowText>{policyData?.vehicle?.TB_CONSTANT_VEHICLE_COLOR?.name}</RowText>
              </Row>
              <Row>
                <RowLabel>Placa:</RowLabel>
                <RowText>{policyData?.vehicle?.plate === null ? 'Sem placa' : formatPlate(policyData?.vehicle?.plate)}</RowText>
              </Row>
              <Row>
                <RowLabel>Ano fabricação/modelo:</RowLabel>
                <RowText>
                  {policyData?.vehicle?.manufacture_year}/{policyData?.vehicle?.year_model}
                </RowText>
              </Row>
              <Row>
                <RowLabel>Chassi:</RowLabel>
                <RowText>{policyData?.vehicle?.chassi}</RowText>
              </Row>
              <Row>
                <RowLabel>Valor*:</RowLabel>
                <RowText>{policyData?.vehicle?.value && formatCurrency(policyData?.vehicle?.value)}</RowText>
              </Row>
              <Row>
                <RowLabel>Código Fipe:</RowLabel>
                <RowText>{policyData?.vehicle?.TB_CONSTANT_VEHICLE_MODELS?.fipe_code}</RowText>
              </Row>
              <Row>
                <RowLabel>Veículo de Leilão:</RowLabel>
                <RowText>{policyData?.vehicle?.auction || policyData?.vehicle?.auction === 1 ? 'Sim' : 'Não'}</RowText>
              </Row>
              <Row>
                <RowLabel>Uso do veículo:</RowLabel>
                <RowText>{policyData?.vehicle?.TB_CONSTANT_VEHICLE_USES.name}</RowText>
              </Row>
              <Row>
                <RowLabel>Chassis Remarcado/Veiculo Recuperado de Sinistro:</RowLabel>
                <RowText>{!!policyData?.vehicle?.remarked ? 'Sim' : 'Não'}</RowText>
              </Row>
              <Row>
                <RowLabel>0km:</RowLabel>
                <RowText>{!!policyData?.vehicle?.zero_km ? 'Sim' : 'Não'}</RowText>
              </Row>
              <div class="page-break" />
              <Row>
                <RowLabel>Possui GNV:</RowLabel>
                <RowText>{!!policyData?.vehicle?.gnv ? 'Sim' : 'Não'}</RowText>
              </Row>
              <Row>
                <RowLabel>Região de Circulação:</RowLabel>
                <RowText>
                  {policyData?.address?.city} / {policyData?.address?.state}
                </RowText>
              </Row>

              <SectionSmall>* Valor da Tabela Fipe do veículo na data da contratação</SectionSmall>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>DADOS DA APÓLICE</SectionTitle>
              </SectionContainer>

              <Row>
                <RowLabel>Modalidade de Contratação:</RowLabel>
                <RowText> VMR - Valor de Mercado Referenciado</RowText>
              </Row>

              <Row>
                <RowLabel>Fator de Ajuste LMI: </RowLabel>
                <RowText>{adjustmentFactor}*</RowText>
              </Row>

              <Column>
                <b>LIMITE MÁXIMO DE INDENIZAÇÃO (LMI):</b>
                <p>
                  Valor máximo a ser pago pela SEGURADORA, contratada para cada cobertura, escolhida pelo SEGURADO(A) e expresso
                  na apólice.
                </p>
              </Column>

              <Row>
                <RowLabel>PEÇAS E REPAROS:</RowLabel>
                <RowText>
                  UTILIZAÇÃO DE PEÇAS (NOVAS, USADAS, ORIGINAIS OU NÃO), desde que mantenham a especificação técnica do
                  fabricante.
                </RowText>
              </Row>

              {hasCoverages && (
                <>
                  <SectionContainer>
                    <SectionTitle>COBERTURAS</SectionTitle>
                  </SectionContainer>

                  <table>
                    <thead>
                      <tr>
                        <td width={300}>Cobertura</td>
                        <td width={200}>LMI **</td>
                        <td width={150}>Prêmio</td>
                        <td width={250}>Franquias</td>
                      </tr>
                    </thead>
                    <tbody>
                      {policyData?.policyData?.coverages
                        ? policyData?.policyData?.coverages
                            .filter((item) => item.isService === false)
                            .map((product) => (
                              <tr>
                                <td>{product?.name}</td>
                                <td>{formatCurrency(product?.lmi || '--')}</td>
                                <td>{formatCurrency(product?.value || '--')}</td>
                                <td>{product?.deductible || '--'}</td>
                              </tr>
                            ))
                        : ''}
                    </tbody>
                  </table>
                </>
              )}
              {hasServices && (
                <>
                  <SectionContainer>
                    <SectionTitle>SERVIÇOS</SectionTitle>
                  </SectionContainer>
                  <table>
                    <thead>
                      <tr>
                        <td width={300}>Serviço</td>
                        <td width={200}>LMI **</td>
                        <td width={150}>Valor</td>
                        <td width={250}>Franquias</td>
                      </tr>
                    </thead>
                    <tbody>
                      {policyData?.policyData?.coverages
                        ? policyData?.policyData?.coverages
                            .filter((item) => item.isService === true)
                            .map((product) => (
                              <tr>
                                <td>{product?.name}</td>
                                <td>{formatCurrency(product?.lmi || '--')}</td>
                                <td>{formatCurrency(product?.value || '--')}</td>
                                <td>{product?.deductible || '--'}</td>
                              </tr>
                            ))
                        : ''}
                    </tbody>
                  </table>
                </>
              )}
              <SectionSmall>
                Em caso de indenização integral ou roubo e furto total, será aplicada a franquia regressiva estabelecida nas
                condições gerais da apólice.
              </SectionSmall>

              <br />

              <SectionSmall>* Se selecionada a opção de leilão, o fator de ajuste deverá ser de 70%</SectionSmall>
              <SectionSmall>** Limite máximo de indenização</SectionSmall>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>VALOR DA APÓLICE</SectionTitle>
              </SectionContainer>

              {hasCoverages && (
                <Row>
                  <RowLabel>Prêmio Líquido Coberturas:</RowLabel>
                  <RowText>
                    {formatCurrency(
                      (policyData?.isHinova ? policyData?.valueFinal : valueCoverage) - policyData?.proposalData?.iof,
                    )}
                  </RowText>
                </Row>
              )}
              <Row>
                <RowLabel>IOF:</RowLabel>
                <RowText>{formatCurrency(policyData?.proposalData?.iof || 0)}</RowText>
              </Row>

              {hasCoverages && (
                <Row>
                  <RowLabel>Prêmio Total Coberturas:</RowLabel>
                  <RowText>
                    {formatCurrency(
                      (policyData?.isHinova ? policyData?.valueFinal : policyData?.price?.total_premium) - valueService,
                    )}
                  </RowText>
                </Row>
              )}

              {hasServices && (
                <Row>
                  <RowLabel>Custo Serviços:</RowLabel>
                  <RowText> {formatCurrency(valueService)}</RowText>
                </Row>
              )}

              <Row>
                <RowLabel>Valor Total Seguro:</RowLabel>
                <RowText>
                  {' '}
                  {policyData?.isHinova
                    ? formatCurrency(policyData?.valueFinal || 0)
                    : formatCurrency(policyData?.price?.total_premium || 0)}
                </RowText>
              </Row>

              <Row>
                <RowLabel>Forma de pagamento:</RowLabel>
                <RowText> {policyData?.price?.payment_method}</RowText>
              </Row>
            </section>

            <div class="page-break" />

            <section style={{ borderBottom: 'none' }}>
              <SectionContainer>
                <SectionTitle>RESUMO DAS COBERTURAS</SectionTitle>
              </SectionContainer>

              <b>Canais de Atendimento</b>
              <h4>SAC: {policyData?.policyData?.provider_assistance?.phone_sac}</h4>
              <h4>Email: {policyData?.policyData?.provider_assistance?.email}</h4>
              <h4>Assistência 24h: {policyData?.policyData?.provider_assistance?.phone_support}</h4>

              <br />

              <h2>A íntegra das condições gerais deste produto, podem ser consultadas no portal www.splitrisk.com.br</h2>

              <h2>
                É fundamental que o segurado tenha conhecimento das cláusulas: 'Cláusula de riscos excluídos', ‘Perdas de
                Direitos', 'Obrigações do Segurado' e 'Cláusula de participação proporcional ou franquia', constantes nas
                condições gerais.
              </h2>

              {policyData?.policyData?.coverages
                ? policyData?.policyData?.coverages.map((product, index) => (
                    <>
                      {index === 3 && <div class="page-break" />}
                      <span className="generalConditionsTitle">{product?.name}</span>{' '}
                      <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>{product?.description}</p>
                    </>
                  ))
                : ''}

              <>
                <span className="generalConditionsTitle">Valor determinado:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  É a modalidade de seguro, que garante ao Segurado, no caso de indenização integral, roubo ou furto do veículo, o
                  pagamento de quantia fixa, em moeda corrente nacional, conforme o LMI-Limite Máximo de Indenização e Fator de
                  Ajuste estabelecidos no ato da contratação e independentemente do valor de mercado do veículo quando da
                  ocorrência do sinistro.
                </p>
              </>

              <>
                <span className="generalConditionsTitle">Fator de ajuste:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  É o percentual estabelecido pela SEGURADORA ou seu corretor de seguros no ato da contratação do seguro, que será
                  aplicado sobre o valor que constar no LMI-Limite Máximo de Indenização de cotação para o veículo, para a
                  estipulação do valor da indenização integral do veículo segurado. A aplicação do fator de ajuste pode resultar
                  em valor inferior àquele valor do LMI-Limite Máximo de Indenização estabelecido na proposta, de acordo com as
                  características do veículo e seu estado de conservação.
                </p>
              </>
            </section>

            <section>
              <SectionSmall style={{ marginTop: 30 }}>
                O Seguro Auto é um produto da Split Risk Seguradora regularizada na SUSEP, pelo projeto Sandbox, no processo
                15414.618377/2020-87: https://www.gov.br/susep/pt-br/assuntos/sandbox-regulatorio/sandbox-regulatorio-1a-edicao
              </SectionSmall>
            </section>
          </Printable>
        </Container>
      )}

      <Footer>
        <FooterRow>
          <FooterText>
            <p>
              Apólice emitida por: <b>{policyData?.policyData?.provider_assistance?.social_reason}</b> | CNPJ:{' '}
              {policyData?.policyData?.provider_assistance?.cnpj}{' '}
            </p>
          </FooterText>
          <LogoIcon>
            <img src={policyData?.logo || ''} alt="Logo parceiro" />
          </LogoIcon>
        </FooterRow>
      </Footer>
    </>
  );
}

export default BackofficePrintPolicy;
